<template>
  <hcc-modal name="edit-question-modal" :title="question?.id ? $t('questions.edit') : $t('questions.add')"
    :scrollable="true" @before-open="beforeOpen" @opened="setData"
    @confirm="handleConfirm" @closed="updateQuestion"
    :disableConfirmButton="!hasEdits || !hasData">
    <div class="questions__info">
      <hcc-input :label="$t('questions.text')" v-model="text" size="xlg" :requiredInput="true" />
    </div>
    <div class="questions__info">
      <hcc-input :label="$t('questions.points')" type="number" v-model="points" size="xlg" :requiredInput="true" />
    </div>
    <div class="questions__info">
      <hcc-input :label="$t('questions.category')" v-model="category" size="xlg" :requiredInput="true" />
    </div>
    <div class="questions__info">
        <hcc-select :label="$t('questions.answer')"
          :options="answers"
          v-model="answer"
          optionLabel="text" track-by="value"
          custom-class="responsive-select"
          :requiredInput="true"
          size="xlg"
        />
      </div>
    <div class="questions__info">
      <hcc-input :label="$t('questions.A')" v-model="optionA"
        size="xlg" :requiredInput="true" />
    </div>
    <div class="questions__info">
      <hcc-input :label="$t('questions.B')" v-model="optionB"
        size="xlg" :requiredInput="true" />
    </div>
    <div class="questions__info">
      <hcc-input :label="$t('questions.C')" v-model="optionC"
        size="xlg"/>
    </div>
    <div class="questions__info">
      <hcc-input :label="$t('questions.D')" v-model="optionD"
        size="xlg"/>
    </div>
  </hcc-modal>
</template>

<script>
import HccModal from '../components/HccModal/index.vue'
import HccInput from '../components/HccInput.vue'
import HccSelect from '../components/HccSelect.vue'

export default {
  components: { HccModal, HccInput, HccSelect },
  data() {
    return {
      text: '',
      points: 0,
      category: '',
      answer: null,
      optionA: '',
      optionB: '',
      optionC: '',
      optionD: '',
      answers: [
        { value: 'A', text: this.$t('questions.A') },
        { value: 'B', text: this.$t('questions.B') },
        { value: 'C', text: this.$t('questions.C') },
        { value: 'D', text: this.$t('questions.D') }
      ],
      question: null
    }
  },
  computed: {
    hasEdits() {
      return this.text !== this.question?.text ||
        this.points !== this.question?.points ||
        this.category !== this.question?.category ||
        this.answer !== this.question?.answer ||
        this.optionA !== this.question?.optionA ||
        this.optionB !== this.question?.optionB ||
        this.optionC !== this.question?.optionC ||
        this.optionD !== this.question?.optionD
    },
    hasData() {
      return !!this.text &&
        !!this.points &&
        !!this.category &&
        !!this.answer &&
        !!this.optionA &&
        !!this.optionB
    }
  },
  methods: {
    beforeOpen(event) {
      this.question = event.params
    },
    setData() {
      this.text = this.question.texto
      this.points = this.question.puntos
      this.category = this.question.categoria
      this.answer = this.answers
        .find(
          item => item.value === this.question.respuesta
        )
      this.optionA = this.question.A
      this.optionB = this.question.B
      this.optionC = this.question.C
      this.optionD = this.question.D
    },
    handleConfirm() {
      if (this.hasEdits) {
        const editedQuestion = {
          ...this.question,
          texto: this.text,
          puntos: this.points,
          categoria: this.category,
          respuesta: this.answer.value,
          A: this.optionA || null,
          B: this.optionB || null,
          C: this.optionC || null,
          D: this.optionD || null
        }
        this.$store.dispatch('updateQuestion', editedQuestion)
      }
    },
    updateQuestion() {
      this.$emit('update-question')
    }
  }
}
</script>

<style scoped>
  .select-container {
    width: 100%;
  }
</style>
