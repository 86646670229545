<template>
  <div>
    <overlay-loader :loading="false" />
    <hcc-button-icon @click="addQuestion()"
      v-tooltip="$t('questions.add')">
      <plus-icon />
    </hcc-button-icon>
    <hcc-table :title="$t('questions.title')" :columns="columns" :rows="questions"
      :pagination="questions.length > 10" :rowsPerPage="10" enableSearch
      @search="() => { }">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field == 'ticket-actions'"
          class="ticket__actions">
          <hcc-button-icon @click="editQuestion(props.row)"
            v-tooltip="$t('questions.edit')">
            <edit-icon />
          </hcc-button-icon>
        </div>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <edit-question-modal @update-question="updateQuestion" />
  </div>
</template>

<script>
import HccTable from '../components/HccTable.vue'
import HccButtonIcon from '../components/HccButtonIcon.vue'
import OverlayLoader from '../components/OverlayLoader.vue'
import EditIcon from '@/assets/icons/pencil.svg'
import PlusIcon from '@/assets/icons/plus-circle.svg'
import EditQuestionModal from '../components/EditQuestionModal.vue'
import { mapState } from 'vuex'

export default {
  components: { HccTable, OverlayLoader, HccButtonIcon, EditIcon, PlusIcon, EditQuestionModal },
  mounted() {
    this.$store.dispatch('getQuestions')
  },
  computed: {
    ...mapState({
      questions: state => state.questions,
      loading: state => state.loading
    }),
    columns() {
      return [
        {
          label: this.$t('questions.text'),
          field: 'texto',
          sortable: false
        },
        {
          label: this.$t('questions.points'),
          field: 'puntos'
        },
        {
          label: this.$t('questions.category'),
          field: 'categoria'
        },
        {
          label: this.$t('questions.answer'),
          field: 'respuesta'
        },
        {
          label: this.$t('questions.A'),
          field: 'A'
        },
        {
          label: this.$t('questions.B'),
          field: 'B'
        },
        {
          label: this.$t('questions.C'),
          field: 'C'
        },
        {
          label: this.$t('questions.D'),
          field: 'D'
        },
        {
          label: this.$t('tickets.actions'),
          field: 'ticket-actions',
          sortable: false
        }
      ]
    }
  },
  methods: {
    editQuestion(question) {
      this.$modal.show('edit-question-modal', question)
    },
    addQuestion() {
      this.$modal.show('edit-question-modal', {})
    },
    updateQuestion() {
      this.$store.dispatch('getQuestions')
    }
  }
}
</script>

<style lang="scss">
@import "~styles/views/questions.scss";
</style>
